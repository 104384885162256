import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import Container from "../components/container"
import Section from "../components/section"
import GradientBackground from "../components/gradient-background"
import media from "styled-media-query"

const AboutTextArea = styled.section`
  font-weight: 200;
  column-count: 1;
  column-gap: 1rem;
  color: ${props => props.color || ""};

  ${media.greaterThan("small")`
    column-count: 2;
  `}
`

function About() {
  const {
    markdownRemark: { html },
  } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
        html
        frontmatter {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title="About" />
      <GradientBackground
        gradientValues={[
          "rgba(124, 123, 123, 1) 0%",
          "rgba(183, 65, 66, 1) 30%",
          "rgba(246, 5, 129, 1) 100%",
        ]}
      />
      <Section>
        <Container>
          <AboutTextArea
            dangerouslySetInnerHTML={{ __html: html }}
          ></AboutTextArea>
        </Container>
      </Section>
    </>
  )
}

export default About
